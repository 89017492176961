@use '~@hallmark/web.styles.colors/colors.module.scss' as colors;
@use '~@hallmark/web.styles.sizes/sizes.module.scss' as sizes;
@use '~@hallmark/web.styles.util/util.module.scss' as util;

:root {
  // Left and Right
  --drawer-left-right-max-width: 375px;
  --drawer-left-right-max-width-mobile-s: 24px;
  --drawer-left-right-max-width-mobile-l: 32px;
  --drawer-left-right-close-icon-size: 26px;
  --drawer-left-right-close-icon-size-mobile: 26px;

  // Left
  --drawer-left-border-radius: 0;
  --drawer-left-border-radius-mobile: 0;
  --drawer-left-padding: 0;
  --drawer-left-padding-mobile: 0;
  --drawer-left-box-shadow: #{util.$modal-dropshadow};
  --drawer-left-header-margin-bottom: 0;
  --drawer-left-header-margin-bottom-mobile: 0;
  --drawer-left-header-margin-top: auto;
  --drawer-left-header-flex-direction: row;
  --drawer-left-header-justify-content: unset;
  --drawer-left-footer-box-shadow: #{util.$shadow-1};
  --drawer-left-footer-padding: 1.25rem;
  --drawer-left-footer-margin-top: 1.25rem;
  --drawer-left-body-padding-mobile: 0 20px;
  --drawer-left-body-padding: 0 1.25rem;
  --drawer-left-content-flex: 1 1;
  --drawer-left-content-margin: auto;
  --drawer-left-content-justify-content: space-between;

  // Right
  --drawer-right-border-radius: 0;
  --drawer-right-border-radius-mobile: 0;

  // Bottom
  --drawer-bottom-padding: 0;
  --drawer-bottom-padding-mobile: 0;
  --drawer-bottom-background-color: #{colors.$white};
  --drawer-bottom-border-top-radius: 0;
  --drawer-bottom-height: auto;
  --drawer-bottom-height-mobile: auto;
  --drawer-bottom-max-height: 104px;
  --drawer-bottom-max-height-mobile: unset;
  --drawer-bottom-box-shadow: #{util.$modal-dropshadow};
  --drawer-bottom-flex-direction: column; // same
  --drawer-bottom-alignment: unset;
  --drawer-bottom-justify-content: unset;
  --drawer-bottom-child-height: unset;
  --drawer-bottom-close-padding: 16px 0;

  // Container
  --container-header-display: flex;
  --container-header-margin: unset;
  --container-header-width: 100%;
  --container-content-margin: unset;
  --container-content-width: 100%;
}

.drawer {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;

  .veil {
    background: rgba(colors.$dark-gray, 0.05);
    width: 100vw;
    height: 100vh;
    overflow: hidden;
  }

  &.is-open {
    display: flex;

    .container {
      opacity: 1;
    }
  }
}

.container {
  background-color: colors.$white;
  box-shadow: util.$shadow-3;
  box-sizing: border-box;
  width: 100%;
  opacity: 0;
  position: fixed;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;

  &.hide {
    display: none;
  }

  .header {
    display: flex;
    flex-flow: nowrap;
    align-items: center;

    .side-drawer-close,
    .top-drawer-close {
      display: flex;
      padding: util.rem(16px) util.rem(0px);
    }
  }

  &.top .header,
  &.right .header,
  &.bottom .header {
    flex-direction: row-reverse;
    justify-content: space-between;
  }

  .content {
    min-width: 0;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: space-between;
  }

  .drawer-body {
    flex-grow: 1;
    overflow-y: auto;
    padding: 0 util.rem(20px);
  }

  .drawer-footer {
    padding: util.rem(20px);
    margin-top: util.rem(20px);
    box-shadow: util.$shadow-1;
    background-color: colors.$white;
    z-index: 10;

    &.sticky {
      position: sticky;
      bottom: 0;
    }
  }

  &.left,
  &.right {
    overflow-y: auto;
    overflow-x: hidden;
    will-change: opacity, transform;
    max-width: var(--drawer-left-right-max-width);

    @include util.media(sizes.$mq-mobile-l) {
      max-width: calc(100% - var(--drawer-left-right-max-width-mobile-m));
    }

    @include util.media(sizes.$mq-mobile-s) {
      max-width: calc(100% - var(--drawer-left-right-max-width-mobile-s));
    }

    .header {
      .side-drawer-close,
      .top-drawer-close {
        span:before {
          font-size: var(--drawer-left-right-close-icon-size);

          @include util.media(sizes.$mq-mobile) {
            font-size: var(--drawer-left-right-close-icon-size-mobile);
          }
        }
      }
    }
  }

  &.left {
    top: 0;
    bottom: 0;
    transform: translate(-100%, 0);
    animation: openFromLeft 0.24s linear forwards 0.08s;
    border-radius: var(--drawer-left-border-radius);
    padding: var(--drawer-left-padding);
    box-shadow: var(--drawer-left-box-shadow);

    @include util.media(sizes.$mq-mobile) {
      border-radius: var(--drawer-left-border-radius-mobile);
      padding: var(--drawer-left-padding-mobile);
    }

    .header {
      margin-bottom: var(--drawer-left-header-margin-bottom);
      margin-top: var(--drawer-left-header-margin-top);
      flex-direction: var(--drawer-left-header-flex-direction);
      justify-content: var(--drawer-left-header-justify-content);
      align-items: center;

      @include util.media(sizes.$mq-mobile) {
        margin-bottom: var(--drawer-left-header-margin-bottom-mobile);
      }

      .side-drawer-close,
      .top-drawer-close {
        padding: var(--drawer-bottom-close-padding);
      }
    }

    .content {
      justify-content: var(--drawer-left-content-justify-content);
      flex: var(--drawer-left-content-flex);
      margin: var(--drawer-left-content-margin);
    }

    .drawer-footer {
      box-shadow: var(--drawer-left-footer-box-shadow);
      padding: var(--drawer-left-footer-padding);
      margin-top: var(--drawer-left-footer-margin-top);
    }

    .drawer-body {
      padding: var(--drawer-left-body-padding);

      @include util.media(sizes.$mq-mobile) {
        padding: var(--drawer-left-body-padding-mobile);
      }
    }
  }

  &.right {
    right: 0;
    bottom: 0;
    transform: translate(100%, 0);
    animation: openFromRight 0.24s linear forwards 0.08s;
    border-radius: var(--drawer-right-border-radius);

    @include util.media(sizes.$mq-mobile) {
      border-radius: var(--drawer-right-border-radius-mobile);
    }
  }

  &.top {
    max-height: calc(100vh - 104px);
    left: 0;
    right: 0;
    bottom: auto;
    will-change: opacity, transform;
    transform: translate(0, -100%);
    animation: openFromTop 0.24s linear forwards 0.08s;
  }

  &.bottom {
    left: 0;
    right: 0;
    top: auto;
    will-change: opacity, transform;
    transform: translate(0, 100%);
    animation: openFromBottom 0.24s linear forwards 0.08s;
    padding: var(--drawer-bottom-padding);
    background-color: var(--drawer-bottom-background-color);
    border-top-left-radius: var(--drawer-bottom-border-top-radius);
    border-top-right-radius: var(--drawer-bottom-border-top-radius);
    min-height: var(--drawer-bottom-height);
    flex-direction: var(--drawer-bottom-flex-direction);
    align-items: var(--drawer-bottom-alignment);
    justify-content: var(--drawer-bottom-justify-content);
    box-shadow: var(--drawer-bottom-box-shadow);

    @include util.media(sizes.$mq-mobile) {
      padding: var(--drawer-bottom-padding-mobile);
      height: var(--drawer-bottom-height-mobile);
      max-height: var(--drawer-bottom-max-height-mobile);
    }

    .header,
    .content {
      height: var(--drawer-bottom-child-height);
    }

    .side-drawer-close,
    .top-drawer-close {
      padding: var(--drawer-bottom-close-padding);
    }
  }
}

.container {
  .header {
    display: var(--container-header-display);
    align-items: center;
    margin: var(--container-header-margin);
    width: var(--container-header-width);
  }
  .content {
    margin: var(--container-content-margin);
    width: var(--container-content-width);
  }

  &.layout-absolute {
    padding: util.rem(20px) 0;

    @include util.media(sizes.$mq-mobile) {
      padding: util.rem(40px) util.rem(24px) util.rem(24px) util.rem(24px);
      gap: util.rem(16px);
    }

    .drawer-body {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: max-content;
      padding: 0 util.rem(56px) 0 util.rem(32px);
      background: linear-gradient(270deg, #fdf9f2 85%, rgba(253, 249, 242, 0) 95%);
      border-top-right-radius: util.rem(24px);

      @include util.media(sizes.$mq-mobile) {
        border-top-right-radius: 0;
        position: static;
        background: transparent;
        padding: 0;
        margin: auto;

        button {
          width: util.rem(240px);
          height: util.rem(40px);
        }
      }
    }
  }

  &.layout-limited {
    gap: util.rem(16px);
    padding: util.rem(20px) 0 util.rem(20px) 0;

    @include util.media(sizes.$mq-mobile) {
      padding: util.rem(20px) util.rem(20px) util.rem(20px) util.rem(20px);
      gap: util.rem(10px);
    }

    .header {
      max-width: util.rem(940px);

      @include util.media(sizes.$mq-mobile) {
        max-width: unset;
      }
    }

    .content {
      max-width: util.rem(940px);

      @include util.media(sizes.$mq-mobile) {
        max-width: unset;
      }

      .drawer-body {
        padding: 0;
      }
    }
  }

  &.layout-full {
    .drawer-header {
      width: calc(100% - 200px);
    }
  }
}

/** Animation Keyframes */
@keyframes openFromTop {
  from {
    opacity: 0;
    transform: translate(0, -100%);
  }

  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}

@keyframes openFromBottom {
  from {
    opacity: 0;
    transform: translate(0, 100%);
  }

  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}

@keyframes openFromLeft {
  from {
    opacity: 0;
    transform: translate(-100%, 0);
  }

  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}

@keyframes openFromRight {
  from {
    opacity: 0;
    transform: translate(100%, 0);
  }

  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}

/* Composition and Story Styles */

.footer-button {
  width: 100%;
  max-width: 100%;
  box-shadow: util.$shadow-1;
}

.composition-close-icon {
  span:before {
    color: white;
  }

  &:hover {
    // Use nth-child(2) to override Icon's styling specificity (w/o using !important)
    :nth-child(2):before {
      color: red;
    }
  }
}
