@use '~@hallmark/web.styles.colors/colors.module.scss' as colors;
@use '~@hallmark/web.styles.util/util.module.scss' as util;
@use '~@hallmark/web.styles.sizes/sizes.module.scss' as sizes;

.photo-drawer {
  z-index: 10;
  position: absolute;
  left: 0;

  /* Header section */
  & > div:first-child {
    display: flex;
    flex-direction: row-reverse !important;
    padding: 0 util.rem(20px);
    margin-bottom: util.rem(12px) !important;
    margin-top: util.rem(8px) !important;

    h5 {
      margin-bottom: 0;
    }
  }

  & > div:nth-child(2) > div:first-child {
    flex-direction: row-reverse;
    width: auto;
    margin: 0 sizes.$space-16;
    z-index: 1;
    padding: 0;
  }

  & > div > :last-child {
    position: relative;
    width: 100%;
    height: 100%;
  }

  /* Photo drawer body */
  & > :last-child {
    max-width: 450px !important;
    padding-bottom: util.rem(20px);

    & > div > div > div > div > [data-testid='photo-upload-button'] {
      height: util.rem(109px);
    }
  }

  /* Upload photo button */
  span {
    > span {
      font-size: util.rem(12px);
    }
  }
}

.no-photos-uploaded-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  border: 2px dashed var(--border-color);
  border-radius: sizes.$space-8;
  background-color: var(--background-color);
  cursor: pointer;

  .no-photos-uploaded-list {
    max-width: 90%;
  }

  .no-photos-uploaded-step {
    color: colors.$medium-gray;
    text-align: left;
  }

  .photo-lot-icon {
    margin-bottom: sizes.$space-24;
  }

  .no-photos-uploaded-title {
    font-weight: 400;
  }
}

.photos-uploaded-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  position: relative;

  .photos-uploaded-list {
    display: grid;
    grid-template-columns: repeat(3, 32%);
    grid-template-rows: repeat(1, 1fr);
    grid-column-gap: sizes.$space-8;
    grid-row-gap: sizes.$space-8;
    width: 100%;

    .photo-upload-button {
      background: none;
      & > span {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 109px; // Upload button is same height as uploaded photos -- 109px
        cursor: pointer;
        & > span {
          margin-bottom: sizes.$space-8;
        }
      }

      border: 2px dashed var(--border-color);
      border-radius: sizes.$space-8;
    }

    .photo-selectable {
      cursor: pointer;
    }

    .photo-container {
      position: relative;
      .photo-loading {
        opacity: 50%;
      }

      img {
        width: 100%;
        aspect-ratio: 1;
        object-fit: cover;
        border-radius: sizes.$space-8;
      }
    }
  }
}

.photo-upload-buttons-container {
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-start;
  margin-top: sizes.$space-24;
  width: 100%;
  background-color: white;
  padding: sizes.$space-16;
  z-index: 10;

  &.sticky {
    position: sticky;
    bottom: 0;
  }
}

.photo-delete-button {
  margin-left: sizes.$space-8;
}

.drawer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.drawer-close-icon {
  span:before {
    color: var(--primary-button-disabled-border-color);
  }

  &:hover {
    // Use nth-child(2) to override Icon's styling specificity (w/o using !important)
    :nth-child(2):before {
      color: var(--primary-button-disabled-background-color);
    }
  }
}

.drawer-header {
  width: 100%;
  margin-bottom: sizes.$space-12;
  margin-top: sizes.$space-12;
  & > h5 {
    margin-bottom: sizes.$space-12;
  }
}

.outer-circle {
  background: var(--secondary-button-background-color);
  border: 2px solid var(--secondary-button-border-color);
  border-radius: 50%;
  height: 24px;
  width: 24px;
  position: absolute;
  right: 5%;
  top: 5%;
  /* 
   Child elements with absolute positioning will be 
   positioned relative to this div 
  */
}

.inner-circle {
  position: absolute;
  background: var(--primary-button-background-color);
  border-radius: 50%;
  height: 16px;
  width: 16px;
  /*
   Put top edge and left edge in the center
  */
  top: 50%;
  left: 50%;
  margin: -8px 0px 0px -8px;
  /* 
   Offset the position correctly with
   minus half of the width and minus half of the height 
  */
}
